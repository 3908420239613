@font-face { font-family: roboto-regular; 
  src: url('../www/Roboto-Regular.ttf'); } 
  @font-face { font-family: roboto-bold; 
    src: url('../www/Roboto-Bold.ttf'); } 
    @font-face { font-family: lato-regular; 
      src: url('../www/Lato-Regular.ttf'); } 
body{
  /* font-family: lato-regular;  */
  /* font-family: "Lato", Arial, sans-serif; */
  font-family: "Roboto" !important;
  font-size: 16px;
  line-height: 1.8;
  font-weight: normal;
  background: #f8f9fd;
  color: gray;
  overflow-y: hidden !important;
  /* background-image: url("./images/LogoEmpresa1.png");
  background-color: #cccccc; */
}
.echarts-for-react{
  height: 300px !important;
  width: 100% !important;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.col-factura-items .form-outline{
  height: 37px !important;
}
.col-factura-items{
  height: 45px !important;
}
.btn-cancelar-position{
  position: relative;
  left: 10px;
}
.label-no-record{
  position: relative;
  top: 5px;
  color: gray;
}
.text-start-r{
  margin: auto;
  text-align: left !important;
  width: 98% !important;
  padding: unset;
}
.height-40, .height-40 td{
  height: 40px !important;
}
.col-no-record{
  background-color: #f5f5f5;
  text-align: center;
  height: 40px;
}
.float-right-btn{
  float:right !important;
}
.css-imq00f-MuiTableCell-root{
  padding: unset !important;
}
.scrol-table3{
  height: 70vh;
  overflow-y: auto;
}
.scrol-table2{
  height: 80vh;
  overflow-y: auto;
  color: black !important;
}
.scrol-table{
  height: 63vh;
  overflow-y: auto;
}
.layout-bg-border{
  box-shadow: 0 0 8px 2px rgb(0 0 0/5%);
}
.fixed-div{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 5000;
}
.label-bold-roboto{
  font-family: roboto-bold;
  color: black;
  font-size: 20px;
}
.background-divs{
background-color: #2F2F2F;
}
.background-divs label{
  color:white !important
  }
.font-weight-bold-title{
  font-size: 18px;
  font-weight: bold;
}
.font-weight-bold-title-report{
  font-size: 13px;
  font-weight: bold;
}
.text-dark-l{
  color: black !important;
}
.btn-layout label{
  font-size: 15px;
  width: 100%;
  color: black;
  font-family: roboto-bold;
  /* height: 15vh; */
}
.btn-layout a{
  display: contents;
}
.cash-box-img{
  width: 15%;
}
.img-btn-layout{
  width: 55%;
  margin: auto;
  /* height: 130px; */
}
.wrap-login100 {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 77px 55px 33px;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -o-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  -ms-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
}
.menu-color-nav{
    top: 0;
    width: 100%;
    z-index: 1000;
    /* background-color: #2b333e;
    box-shadow: 0 0 8px 2px rgb(0 0 0/60%); */
    margin: 0;
    /* padding: 8px 0;
    color: #fff; */
}
.title-cocina{
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.menu-l{
    color: white !important;
    
}
.menu-l:hover{
    background-color: #3b4554 !important;
}
.menu-l.active{
    background-color: #3b4554 !important;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url("./flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format('woff2');
  }

  @font-face {
    font-family: 'Gellix';
    font-weight: 400;
    font-style: normal;
    src: url("./flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format('woff2');
  }
  @font-face {
    font-family: 'Gellix';
    font-weight: 700;
    font-style: normal;
    src: url("./flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format('woff2');
  }
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }
  .datatable-report tbody tr td label{
 font-size: 12px;
 margin: unset !important;
 position: relative;
 top: 0px;
  }
  
  .datatable-report thead tr{
    background-color: #36304a;
    color: white;
    font-weight: bold;
    height: 10px !important;
  }
  .datatable-report{
    padding: 4% !important;
  }
  .datatable-report thead tr th{
    height: 10px !important;
    padding: 3px !important;
  }
  .datatable-factura thead tr .Mui-TableHeadCell-Content-Labels{
    text-align: center;
    margin: auto;
  }
  .datatable-factura .css-80pr5n-MuiPaper-root{
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.3),0px 2px 2px 0px rgba(0,0,0,0.2),0px 1px 5px 0px rgba(0,0,0,0.2);
  }
  .padding-2porc{
    padding-left: 2% !important;
    padding-right: 2% !important;
  }
  .position-icon
  {
    position: relative;
    top: -10px;
  }
  .datatable-factura-r tr th{
    padding:3px !important;
    background-color: #36304a;
    color: white;
    font-weight: bold;
  }
  .datatable-factura thead tr th{
    padding:3px !important;
    background-color: #36304a;
    color: white;
    font-weight: bold;
  }
  .datatable-factura tbody tr .css-yszbr1-MuiTableCell-root,   .datatable-factura tbody tr .css-11knsbp-MuiTableCell-root, .datatable-factura tbody tr .css-1kof2ay-MuiTableCell-root, .datatable-factura tbody tr .css-xj8wyp-MuiTableCell-root{
    text-align: center;
    /* margin: auto; */
  }
  .class-btn-factura-datatable{
    position: relative;
    top: 6px;
  }
  .button-color-dark{
    background-color: #36304a !important;
    color: #fff !important;
  }
  .button-color-gray{
    background-color: #838689 !important;
    color: #fff !important;
  }
  .barcode-text{
    font-size: 11px;
    text-align: center;
    color: black;
    font-weight: bold
  }
  .barcode-text2{
    top: -17px;
    font-size: 17px;
    position:relative;
    text-align: center;
    color: black;
    font-weight: bold
  }
  .barcode-box{
    margin: auto !important;
    width: 300px;
    height: 0px;
    color: black;
  }
  .style-precio-btn{
    position: relative !important;
    top: -8px !important;
    left: -8px !important;
}

  .barcode-box2{
    width: 300px;
    height: 0px;
  }
  .datatable-factura tbody tr td{
    /* padding: unset !important; */
    /* padding-left: unset !important; */
    padding-left: 5px !important;
     padding-right: unset !important;
      padding-top: unset !important;
    padding-bottom: unset !important; 
  }
  .img-barcode{
    width: 60px;
    height: 45px;
  }
  .src-image-articulo-tooltip {
    width: 70%;
    height: 60%;
}
  .checkbox_reporte label{
    font-weight: bold;
      position: relative;
      top: -3px;
  }
  .width-box-delete-cotizacion{
    width: 120px;
    height: 45px;
  }
  .width-box-delete-articulos{
    width: 210px;
    height: 45px;
  }
  .width-box-delete{
    /* width: 120px; */
    /* height: 40px; */
  }
  .width-box-delete2{
    width: 160px;
    /* height: 40px; */
  }
  .width-box-delete button{
    padding: 3px !important;
  }
  .articulo_drop .dropdown-toggle{
    width: 80% !important;
  }
  .error-label{
    font-weight: bold;
    color: red;
    font-size: 16px;
  }
  .d-flex-none{
    display: inline !important;
  }
  .rdrDateDisplayWrapper{
    display: none !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fsb-10{
    font-size: 10px !important;
    font-weight: bolder !important;
  }
  .label-fpago{
    padding: unset;
    margin: unset;
    font-size: 9px !important;
    font-weight: bold;
  }
  .fsb-12{
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .fsb-12{
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .custom-navs a{
    font-size: 10px !important;
    font-weight: bolder !important;
  }
  .error-label-inventario{
    font-weight: bold;
    color: red;
    font-size: 16px;
    top: -10px;
    position: relative;
  }
  .box-calendar{
    margin: auto;
    box-shadow: 1px 1px 81px rgba(41, 60, 74, .18);
  }
  .report-title{
    font-size: 18px;
    font-weight: bolder;
  }
  .date-p-label{
    position: relative;
    top: 5px;
  }
  .ov-y-inv{
    overflow-y: auto;
    max-height: 30vh;
  }
  .custom-table .table-dark tr th, .custom-table tbody tr th, .custom-table tbody tr td{
    padding: unset !important;
    border:1px solid white !important;
  }
  .class-btn-factura{
    position: relative;
    top: 3px;
  }
.input-iventario-totales{
  font-size: 26px !important;
  font-weight: bold;
  text-align: end;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.3), 0px 2px 2px 0px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.input-iventario-totales2{
  font-size: 34px !important;
  font-weight: bold;
}
  .w-90px{
    width: 90px !important ;
  }
  .modal-add-inventario{
    /* margin-top: 120px; */
    z-index: 1020;
  }
  #react-select-2-listbox{
/* background-color: #3b4554 !important; */
z-index: 1021;
/* height: 20px; */
  }
  .success-label{
    color: #32cd32;
  }
  .width-logo{
    width: 120px;
  }
  .tbody-print tr td{
    height: 10px !important;
    padding: unset !important;
  }
  .tbody-print tr td label{
    position: relative;
    top: 5px;
  }
  .modal-full-large{
    width: 95%;
    margin: auto;
    height: 90%;
    top: 3%;
  }
  .input-edit-cantidad{
    padding: 2px !important;
  }
  .css-74uxa4 button{
    padding: unset !important;
  }
  .css-1e2opbs-MuiButtonBase-root-MuiIconButton-root{
    padding: unset !important;
    margin: unset !important;
  }
  .btn-update-header{
    position: relative;
    top: -15px;
  }
  .css-1q7qbxn-MuiTableRow-root td{
    padding: 3px !important;
  }
  .factura-padding{
    padding: 2% !important;
  }
  .cot-btn-cerrar{
    position: relative;
    right: -45px;
  }
  .cot-btn-save{
    position: relative;
    right: -18px;
  }
  .cot-btn-save82{
    position: relative;
    right: -82px;
  }
  .img-art-datatable{
    margin-left: 5%;
    width: 45px;
    height: 45px;
  }
  .card-cocina{
    max-height: 90vh;
    overflow-y: scroll;
  }
  .card-cocina .card-header{
    font-size: 30px;
    font-weight: bold;
  }
  .card-cocina .card-body h5{
    font-size: 20px;
    font-weight: bold;
  }
  .pdd-3{
    padding: 3%;
  }
  .padd-2p{
    padding: 0.7% !important;
  }
  .img-art-restaurante{
    width: 100%;
    height: 117px;
  }
  .pl-16{
    padding-left: 16px;
  }
  .img-art-datatable-cotizacion{
    margin-left: 5%;
    width: 100px;
    height: 95px;
  }
  .div-categoria{
    overflow-x: auto;
    height: 43vh;
  }
  .cash-box-img-restaurante{
    margin: auto;
    margin-top: 20vh;
    width: 55%;
  }
  .footer-restaurante{
    position: absolute;
    bottom: 11px;
    margin: auto;
  }
  .background-div-item{
    background-color: #f5f5f5;
    border-radius: .5rem !important;
    width: 102%;
    height: 48vh;
  }
  .div-items{
    overflow-x: auto;
    height: 43vh;
  }
  .btn-price-restaurante{
    position: absolute;
    bottom: 15px;
    margin: auto;
    left: 25%;

  }
  .div-items .card{
    min-height: 235px;
    max-height: 235px;
  }
  .div-items .card-body{
    padding: 0%;
  }
  .div-items .card-text{
    font-size: 12px;
    margin-bottom: 2px !important;
  }
  .div-mesas{
    overflow-x: auto;
    height: 20vh;
  }
  .div-container{
    height: 75vh;
  }
  .title-res{
    text-align: center;
    color:black;
    font-weight: bold;
    font-size: 22px;
  }
  .cot-btn-save25{
    position: relative;
    right: -25px;
  }
  .btn-reporte_x{
    margin-left: 3px !important;
    margin-right: 5px !important;
    /* position: relative;
    top: -1px;
    left: +2px; */
  }
  .categoria-btn{
    width: 100%;
    height: 70px;
    margin: 0.3%;
    font-size: 14px;
    color: white;
    padding-top: 5px !important;
    padding-bottom: unset !important;
  }
  .categoria-btn label{
    color: white;
  }
  .mesas-btn{
    width: calc(96% / 7);
    height: 85px;
    margin: 0.2%;
    font-size: 16px;
  }
  .mesas-btn label{
    color: white;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .logo-factura{
    height: 80px;
    position: absolute;
    width: 125px;
    top: 10px;
    right: 10px;
    /* position: absolute;
    width: 125px;
    top: -26px;
    right: 10px; */
  }
  .label-payment{
    color: #285192;
    margin-top: 3px;
    margin-left: 20px;
    padding: unset;
    margin-bottom: 3px;
  }
  .yappy-img-payment{
    position: absolute;
    margin-right: 105px;
    height: 30px;
    width: 29px;
    top: 3px;
    left: 2px;
    border-radius: 51%;
  }
  .div-top{
    background-color: #3b71ca;
    height: 5vh;
  }
  .div-top label{
    padding-top: 15px;
    font-weight: bold;
   color: white;
   font-size: 22px;
  }
  .modal-pagar{
    width: 75% !important;
  }
  .b
  .margin-btn-bodega{
    margin-left: 10px !important;
  }
  .d-ruby{
    display: ruby;
  }
  .btn-payment{
    display: flex;
    margin: 1% !important;
  }
  .porcent-label-exento{
    font-size: 17px;
    color: #dc4c64;
    position: relative;
    font-weight: bold;
    top: 0px;
    right: -30px;
  }
  .porcent-label{
    font-size: 17px;
    color: #3b71ca;
    position: relative;
    font-weight: bold;
    top: 0px;
    right: -30px;
  }
  .modal-delete{
    width: 33%;
  }
  .src-image-addpago{
    height: 30px;
    width: 30px;
    position: relative;
    top: 3px;
    left: 5px;
  }
  .datatable-factura thead tr th{
    border-right: 1px solid white;
  }
  .scroll-dc{
    height: 80vh;
    overflow-y: scroll;
  }
  .src-image{
    height: 30px;
    width: 30px;
    position: relative;
    top: 4px;
    left: 5px;
  }
  .src-image-enviado{
    width: 150px;
    height: 100px;
  }

  /* margin: auto;
  .ml-2{
    margin-left: 10px;
  }
  /* .articulo_drop .dropdown-menu>li:hover{
    color: var(--mdb-dropdown-link-hover-color);
    background-color: var(--mdb-dropdown-link-hover-bg);
    padding-left: 10px;
    font-weight: 500;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .articulo_drop .dropdown-menu>li{
    padding-left: 10px;
    font-weight: 500;
    padding-bottom: 5px;
    padding-top: 5px;
  } */
